.simplebar-content{
    padding: 10px 0 0 !important;
}

.sidebar-nav .nav-item {
    /* padding: 10px 16px 10px 16px; */
    gap: 10px;

}

.sidebar-toggler{
    width: 100% !important;
    justify-content: flex-start !important;
    flex: 1 !important;
    padding: 0 20px;
}

.simplebar-content > li {
    width: 100% !important;
    padding: 0px 20px !important;
    margin: 0 0 10px;
}

.simplebar-content > li.logout-button.text-start {
    padding: 10px 30px !important;
}

.simplebar-content > li.logout-button.text-start  span{
    padding-left: 8px;
}

.sidebar_custom {
    background: #fff;
    border-right: 1px solid #F4F4F4 !important;
}

.sidebar_custom .sidebar-brand {
    background: #fff !important;
}

.sidebar-nav .nav-item a {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    border-left: 4px solid transparent;
    text-align: left;
    color: #202224;
    display: block;
    border-radius: 6px;
    padding: 10px 15px !important;
    position: relative;
}

.sidebar-nav .nav-item a svg path{
    fill: #202224 !important;
}

.sidebar-nav .nav-item a:hover {
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    background: #0D498E;
    border-radius: 6px;

}

.sidebar-nav .nav-item .badge{
    background-color: #FF6666;
    color: #FFFFFF;
    border-radius: 50%;
    margin-left: 10px !important;
    position: absolute;
    right: -8px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-flex;
    height: 22px;
    width: 22px;
    align-items: center;
    justify-content: center;
}

.sidebar-nav .nav-item .active {
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    background: #0D498E;
    border-radius: 6px;
    position: relative;
}

.sidebar-nav .nav-item .active:before{
    content: " ";
    position: absolute;
    width: 6px;
    height: 100%;
    right: calc(100% + 18px);
    border-radius: 0 5px 5px 0;
    background: #0D498E;
    top: 0;
    bottom: 0;
}

.sidebar-nav .nav-item .active svg, .sidebar-nav .nav-item .active svg path, .sidebar-nav .nav-item a:hover svg, .sidebar-nav .nav-item a:hover svg path{
    color: #ffffff !important;
    fill: #ffffff !important;
}

/* logout button css */
.logout-button {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #3477F1;
    /* height: 48px; */
    left: 16px;
    padding: 12px;
    border-radius: 10px;
    gap: 10px;
    background: #ffffff;
    text-align: center;
    width: 80%;
    align-self: center;
    margin-bottom: 20px;

}

.logout-button:hover {
    background-color: hsl(0, 0%, 100%);
    /* Light blue background for hover state */
    color: #2A72B5;
    /* A darker blue color for hover state */
    border-color: #2A72B5;
    /* Border color changes on hover */
}

.logout-button i {
    /* If you're using FontAwesome 5, adjust the class accordingly */
    margin-right: 0.5rem;
    /* Space between icon and text */
}

/* ends here   */

.cancel-btn, .cancel-btn:hover {
    width: 50%;
    padding: 16px 0px;
    border-radius: 8px;
    border: 1px;
    gap: 8px;
    border: 1px solid #CCCCCC;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #212427;

}

.logout-content span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #76787A;

}

.logout-content h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #212427;
    margin-bottom: 0;

}

.logout-btn, .logout-btn:hover {
    width: 50%;
    padding: 16px 0px;
    border-radius: 8px;
    gap: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFFFFF;
    background: #DE3333;
    /* margin-left: 10px; */
}

.btns-container {
    display: flex;
    gap: 10px;
}

.logo-img:hover{
    cursor: pointer;
}

svg.nav-icon  path{
    fill: #000000 !important;
}

svg.nav-icon{
    width: 30px;
}

.sidebar_custom {
    transition: width 0.3s; /* Smooth transition for width change */
}

.sidebar_custom.sidebar-collapsed {
    width: 60px; /* Adjust width when collapsed */
}

.sidebar_custom .logo-img {
    display: block; /* Hide logo when collapsed */
}

.sidebar_custom.sidebar-collapsed .logo-img {
    display: block; /* Show logo when expanded */
}

.nav-icons-only {
    display: flex;
    flex-direction: column; /* Align items vertically */
    justify-content: center; /* Center items */
}

.nav-icons-only .nav-item {
    width: 100%; /* Ensure full width for icons */
    text-align: center; /* Center icons */
}
.sidebar-toggler{
    background-color: #ffffff00 !important;
}


.sidebar-toggler::before{
    display: none !important;
}

.sidebar-toggler .hide-menu-toggle-items{
display: inline-block !important;
}

/* 
.simplebar-placeholder{
    display: none;
  } */

  .nav-title{
    display: none !important;
  }

  .sidebar .sidebar-nav .logout-button{
    position: relative;
    left: unset !important;
    right: unset !important;
}

.sidebar .sidebar-nav .logout-button:before {
    content: '';
    width: 100%;
    max-width: 215px;
    height: 100%;
    position: absolute;
    background: #e82d40;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 6px;
    display: none;
}

.sidebar .sidebar-nav .logout-button:hover:before{
    display: block;
}

.sidebar .sidebar-nav .logout-button:hover *{
    position: relative;
    z-index: 1;
    fill: #fff;
    color: #fff !important;
}

  @media (min-width: 768px) {
    .sidebar.hide:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 5rem !important;
    }

    .sidebar.hide:not(.sidebar-end) {
        left: 0 !important;
        margin: 0 !important;
        width: 5rem !important;
    }

    .sidebar.hide:not(.sidebar-end) .sidebar-nav .nav-item a span{
        display: none;
    }

    .sidebar.hide .sidebar-nav .nav-item span:nth-child(2){
        display: block !important;
        position: fixed;
        color: #ffffff;
        background: #0d498e;
        padding: 5px;
        border-radius: 4px;
        margin: -22px 0 0;
        opacity: 0;
    }
    
    .sidebar.hide .sidebar-nav .nav-item:hover span:nth-child(2){
        transition: .5s;
        transform: translateX(35px);
        opacity: 1;
    }

    .sidebar.hide .sidebar-nav .logout-button span.text-danger{
        display: block !important;
        position: fixed;
        color: #ffffff !important;
        background: #e82d40;
        padding: 5px;
        border-radius: 4px;
        margin: -20px 0 0;
        opacity: 0;
    }

    .sidebar.hide .sidebar-nav .logout-button:hover span.text-danger{
        transition: .5s;
        transform: translateX(35px);
        opacity: 1;
    }

    .sidebar.hide .sidebar-nav .logout-button{
        position: relative;
        left: unset !important;
        right: unset !important;
    }

    .sidebar.hide .sidebar-nav .logout-button:before {
        content: '';
        width: 40px;
        height: 40px;
        position: absolute;
        background: #e82d40;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 6px;
        display: none;
    }

    .sidebar.hide .sidebar-nav .logout-button:hover:before{
        display: block;
    }

    .sidebar.hide .sidebar-nav .logout-button:hover svg, .sidebar.hide .sidebar-nav .logout-button:hover svg path{
        position: relative;
        z-index: 1;
        fill: #fff;
    }

    .sidebar.hide:not(.sidebar-end) .sidebar-nav .nav-item a svg{
        margin-left: -15px !important;
    }

    body .sidebar.hide:not(.sidebar-end) .sidebar-nav .nav-item a span{
        margin-left: -10px !important;
    }

    .sidebar.hide:not(.sidebar-end) .sidebar-toggler .logo-img{
        display: none;
    }
    .sidebar.hide:not(.sidebar-end) .logout-button span {
        display: none;
    }
    .sidebar.hide:not(.sidebar-end) .sidebar-nav .nav-item a span{
        margin-left: 5px !important;
    }
    .sidebar.hide:not(.sidebar-end) .sidebar-nav .nav-item a span svg{
        margin: auto !important;
    }
    .sidebar.hide:not(.sidebar-end) .sidebar-nav .logout-button {
        padding: 10px 25px;
    }
    .sidebar-nav .logout-button:hover,
    .sidebar-nav .logout-button:focus,
    .sidebar-nav .logout-button:active,
    .sidebar-nav .logout-button:focus-visible,
    .sidebar-nav .logout-button:focus-within{
        cursor: pointer;
        background-color: #Fff !important;
        border: none !important;
    }

    .sidebar.hide:not(.sidebar-end) .sidebar-nav  .nav-icon{
        margin-right: 0px !important;
   } 

}

@media screen and (max-width: 1038px) and (min-width: 767px) {
    .tab{
        display: flex  !important;
        gap: 10px;
    }
    .mob-side-toggle{
        display: none !important;
      }
}
@media screen and (min-width: 768px){
    .mob-logo{
        display: none !important;
    }
}
@media screen and (max-width: 767px) {
    .mob-logo{
        display: block;
    }
}
  
.simplebar-content > li a img {
    filter: brightness(0) invert(0);
}

.simplebar-content > li a:hover img,  .simplebar-content > li a.active img{
    filter: brightness(0) invert(1);
}