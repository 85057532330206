.custom_header {
  border-bottom: 1px solid #F4F4F4 !important;

}

.app-logo {
  display: grid;
}

.app-logo .welcome_txt {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #949494;
}

.app-logo .username_txt {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #3F3F41;
}


/* market status indicator css */
.market-status {
  min-width: 120px;
  color: #1F1F1F;
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  font-variant-numeric: tabular-nums;
  display: flex;
  align-items: center;
}

.market-status .indicator-dot {
  margin-right: 4px;
  width: 9px;
  height: 9px;
  border-radius: 32px;
  background: #4A9C52;
}

.market-closed .indicator-dot {
  background: red;
}

/* Override the CoreUI badge styles as needed to match your design */
.market-status .cbadge {
  background-color: #1F1F1F;
  /* This is a Tailwind CSS green color, adjust as needed */
  padding: 0.4em 1em;
  /* Adjust padding to match the screenshot */
  font-size: 0.875rem;
  /* Adjust font size to match the screenshot */
  border-radius: 9999px;
  /* This creates a pill shape */
  font-weight: 500;
  /* Adjust the font weight as needed */
  color: white;
  /* This sets the text color */
}

.market-time {
  padding-top: 4px;
  color: #949494;
  font-size: 10px;
  text-align: center;
  font-weight: 500;
}

/* You might need to add some additional global styles to the CBadge component, depending on your setup */
.custom_header .header-toggler svg {
  color: #1F1F1F !important;
}

.show-menu-toggle {
  display: none;
}

.hide-menu-toggle-items {
  display: block;
}

@media (max-width: 1038px) {
  .show-menu-toggle {
    display: block;
  }

  .hide-menu-toggle-items {
    display: none !important;
  }
}

@media (max-width: 767px) {

  .app-logo .username_txt {
    font-size: 22px;
  }

  .app-logo .welcome_txt {
    font-size: 14px;
  }

  .custom_header .container-fluid {
    justify-content: flex-start !important;
  }
}

@media (max-width: 467px) {
  .app-logo .username_txt {
    font-size: 18px;
  }

  .app-logo .welcome_txt {
    font-size: 12px;
  }
}

.custom_header .logo-container {
  align-items: center;
}

.custom_header .logo-container img {
  height: 30px;
  cursor: pointer;
}

.custom_header .header-nav .nav-item a {
  color: #76787A !important;
}

.custom_header .header-nav .nav-item a.active {
  color: #222838 !important;
  border-bottom: 4px solid #222838 !important;
}

.market-profile-container {
  align-items: center;
  gap: 20px;
}

.market-profile-container img {
  height: 40px;
}

.custom_header .custom-dropdown .dropdown-options {
  width: 180px;
  /* left: -110px; */
  border: 1px solid #F2F4F7 !important;
}

.custom_header .custom-dropdown .dropdown-logout {
  padding: 0;
  color: #DE3333;
  border-top: 1px solid #F2F4F7;
}

.custom_header .nav-item {
  position: relative;
}

.custom_header .nav-item>span.badge {
  top: 5px;
}

.custom_header .nav-item .badge-count {
  justify-content: center;
  background: #DE3333;
  color: #FFF;
  border-radius: 50%;
  font-size: 10px;
  height: 20px;
  width: 20px;
  display: flex;
  position: absolute;
  top: 1px;
  right: -12px;
  align-items: center;
}

.custom_header .custom-dropdown .dropdown-options a.dropdown-option {
  border-bottom: 1px solid #CCC;
}

.custom_header .custom-dropdown .dropdown-options .dropdown-option {
  padding: 12px !important;
}

.custom_header .custom-dropdown .dropdown-options .logout-button {
  width: 100%;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .mob-side-toggle {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .mob-side-toggle {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .custom_header .container-fluid {
    justify-content: space-between !important;
  }
}

.notification--count {
  background: red;
  padding: 2px 4px;
  border-radius: 50px;
  line-height: 1;
  font-size: 11px;
  border: 2px solid;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Rohit css */


.notification-header {
  padding: 15px;
  height: 52px;
}

.notification-header .btn {
  height: 100%;
}

.btn-create-notification {
  width: 100%;
  color: #000;
  background: #ACFADB;
  border: 0;
  border-radius: 0;
}

.btn-create-notification:hover, .btn-create-notification:active {
  color: #000;
  background: #ACFADB;
}
  
.btn-search-notification {
  color: #22293D;
  background: #26E098;
  border-radius: 0;
  border: 0;
}

.btn-search-notification:hover, .btn-search-notification:active {
  color: #22293D;
  background: #26E098;
}

.notification-list {
  width: 350px;
  max-height: calc(100vh - 200px);
  font-size: 14px;
  overflow: auto;
  background: #F4F5F9;
}

.notification-list div {
  background-color: #fff;
}

.notification-dropdown .dropdown-item {
  white-space: normal;
}

.notification-list a.dropdown-item {
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  background-color: unset;
  border: 0px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0px;
}

.notification-list a.dropdown-item svg g path {
  fill: #fff;
}

.notification-dropdown .dropdown-menu {
  inset: 0px 0px auto auto !important;
  transform: translate3d(0px, 38px, 0px) !important;
  overflow: hidden;
}

.upgrade-now-button {
  margin: auto;
  padding: 0.5rem 0.75rem;
  background: linear-gradient(90deg, #BB830A 0%, #E0B548 100%);
  border: 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.notification-dropdown .nav-link {
  display: flex;
  align-items: center;
}