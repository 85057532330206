// Here you can add other styles

body {
    // background: linear-gradient(40deg, #241f53, #000, #000);
    background: #F5F6FA !important;
    background-attachment: fixed;
}

select {
    text-transform: capitalize;
}

.header {
    border: 0;
}

.header-nav .nav-link,
.header-nav .nav-link:hover {
    color: #fff;
}

.app-logo {
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    img {
        padding: 10px 0;
        height: 60px;
    }
}

.home-banner {
    height: calc(100vh - 115px);
}

.home-banner>.container {
    max-width: 100%;
}

.banner-image {
    object-fit: contain;
}

.banner-text-1 {
    color: #357699;
    font-size: 32px;
    text-align: center;
}

.banner-text-2 h2 {
    color: #fff;
}

.banner-text-2 ul {
    color: #357699;
    font-size: 22px;
}

.signal-card {
    position: relative;
    height: calc(100vh - 268px);
    min-height: 500px;
    // border: 1px solid #374149;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0px 30px 60px rgba(41, 48, 64, 0.1);
}

.signal-header {
    display: inline;
    padding: 12px 24px;
    gap: 16px;
    background-color: #293040;
    // border-bottom: 2px solid #000;
    font-weight: bold;
    // display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.chat-logo-close {
    display: flex;
    justify-content: space-between;
    /* This will place children at the start and end of the container */
    align-items: center;
    font-size: 20px;
}

.chat-logo-close img {
    max-width: 50%;
    /* Adjust as needed */
    height: 24px;
}

.chat-logo-close svg:hover {
    cursor: pointer;
}

.chat-logo-close svg {
    max-width: 50%;
    /* Adjust as needed */
    height: auto;
}

.signal-header-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFF;
    padding-top: 10px;
}

.signal-body {
    height: 100%;
    color: #333;
    font-size: 13px;
    overflow-x: hidden;
}

.signal-body td {
    color: #fff;
    background-color: transparent;
}

.signal-body.SUPER_ADMIN {
    height: calc(100vh - 404px);
}

.unread-channel-alerts {
    background: red;
    border-radius: 15px;
    padding: 0 10px;
    font-size: 13px;
    border: 1px solid;
    font-weight: bold;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #000;
}

.circle-red {
    background: red;
}

.circle-white {
    background: white;
}

.circle-green {
    background: green;
}

.alert-dark {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #2a2a2a;
}

.alert-dark .success {
    color: #4faf42;
}

.alert-dark span {
    color: #6d6c6d;
    font-size: 13px;
}

.alert-header {
    padding-bottom: 10px;
    color: #6d6c6d;
    font-size: 26px;
}

.alert-box {
    height: calc(100vh - 228px);
    overflow: scroll;
}

.react-datepicker__tab-loop {
    z-index: 99;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper input {
    border: 1px solid #fff;
    outline: none;
}

.react-datepicker-wrapper input:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

.date-picker-custom-input {
    width: 100%;
    height: 50px;
    padding-left: 12px;
    background: #000;
    color: #fff;
    border: 1px solid #fff;
    text-align: left;
    display: flex;
    align-items: center;
}

.react-datepicker-popper {
    z-index: 9 !important;
}

.loading-background {
    cursor: unset !important;
}

.alerts-box {
    margin: 8px;
    padding: 8px;
    font-size: 12px;
    border: 2px solid #333;
    border-radius: 10px;
}

.alerts-box.unread-alert {
    position: relative;
    background: #32719445;
    cursor: pointer;
}

.alerts-box.unread-alert::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    background: green;
    border-radius: 50%;
    top: -5px;
    left: -5px;
}

.alert-by {
    color: #666;
    font-style: italic;
    text-align: right;
}

.created-on {
    color: #999;
    font-size: 10px;
    font-style: italic;
    text-align: right;
}

.loading-background {
    z-index: 99999 !important;
}

.otp-box {
    width: 350px;
    margin: auto;
}

.otp-input {
    align-items: center;
    justify-content: space-between;
}

.otp-input input {
    margin: 0 !important;
    width: 50px !important;
    height: 50px !important;
    border: 0;
    border-radius: 8px;
    font-size: 25px;
}

.otp-input input:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

.product-card {
    position: relative;
    margin-top: 25px;
    height: 100%;
    padding: 25px;
    background: #374149;
    border-radius: 20px;
}

.product-popular {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -36px;
    background-color: $secondary-base;
    border-radius: 15px 15px 0 0;
    width: 200px;
    text-align: center;
    padding: 6px 0;
}

.product-header {
    border-bottom: 1px solid #989b9f;
}

.product-body {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 45px);
}

.product-price {
    text-align: center;
    font-size: 50px;
    font-weight: 500;
}

.product-overview {
    margin: 0 0 20px 0;
}

.product-benefits {
    padding: 0px 0px 0 25px;
}

.product-idealFor {
    margin: 0 0 20px 0;
}

.product-button button {
    width: 100%;
}

.no-alerts-box {
    color: #999;
}

.modal-content {
    overflow: hidden auto !important;
}

.modal-header {
    position: sticky;
    top: 0;
    background: #000;
    border-color: #333333;
    z-index: 9;
}

.modal-footer {
    position: sticky;
    bottom: 0;
    background: #000;
    border-color: #333333;
}

.notification-count {
    position: absolute;
    top: -3px;
    left: 18px;
    width: 20px;
    height: 20px;
    background: red;
    border-radius: 50%;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.channel-notification {
    position: relative;
    min-width: 30px;

    .notification-count {
        top: -8px;
        left: 11px;
    }
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    max-width: unset;
}

.bg-header {
    background: linear-gradient(45deg, #8c51ff, #f59150);
}

.table {
    margin: 0;
}

.table> :not(caption)>*>* {
    color: inherit;
    background-color: inherit;
}

.table>thead {
    position: sticky;
    top: 0;
    z-index: 9;
}

table thead tr {
    color: #fff;
    background-color: #000;
}

table tbody tr {
    height: 50px;
    color: #fff;
    background-color: transparent;
    transition: 0.3s;
}

table tbody tr.unread-alert {
    color: #000;
    background-color: #fff;
}

table tbody tr.recent-unread-alert {
    animation: blinkAnimation 1s 3;
}

@keyframes blinkAnimation {
    0% {
        color: #000;
        background-color: #fff;
    }

    50% {
        color: #fff;
        background-color: transparent;
    }

    100% {
        color: #000;
        background-color: #fff;
    }
}

.alert-parent,
.alert-child {
    border-color: transparent;
}

.alert-child,
.alert-child-last {
    background-color: #f6f8fe;
}

.admin-table tbody tr:hover {
    background-color: #37414966;
    cursor: pointer;
}

.admin-table tbody tr.alert-child:hover,
.admin-table tbody tr.alert-child-last:hover {
    background-color: inherit;
    cursor: auto;
}

table th,
table td {
    vertical-align: middle;
}

.signal-footer {
    box-sizing: border-box;
    padding: 8px;
    border-top: 1px solid #E8E8E8;
}

.signal-footer .form-control {
    border-color: #357699;
    border-radius: 0 0 0 7px;
}

.signal-footer button {
    border-radius: 0;
    border-radius: 0 0 7px 0;
}

.signal-body td.Open {
    color: #30895e;
    font-weight: bold;
    user-select: none;
}

.signal-body td.Updated {
    color: #f6d800;
    font-weight: bold;
    user-select: none;
}

.signal-body td.Closed {
    color: #975bce;
    font-weight: bold;
    user-select: none;
}

.view-only {
    padding: 8px 10px;
    color: #333;
    background: #E8E8E8;
    font-size: 13px;
    text-align: center;
}

.add-alert-box {
    padding-left: 8px;
    background: #000;
    border-top: 1px solid #37414a;
    display: flex;
    align-items: center;
}

.add-alert-box .speech-to-text {
    color: #fff;
}

.command-tooltip {
    padding: 0 2px;
    font-size: 25px;
    color: #fff;
    background: transparent;
    border: 0;
    display: flex;
}

.alert-input {
    border: 0;
}

.alert-input::placeholder,
#Comment::placeholder {
    color: gray;
}

.alert-input:focus {
    box-shadow: none;
}

.add-alert-box .btn {
    margin: 0 !important;
}

.command-example {
    padding-left: 10px;
    color: #202224;
    font-size: 13px;
}

// Chat CSS Start

.message-container {
    padding: 12px 0;
}

.message-box {
    position: relative;
    padding: 12px 12px;
    display: flex;
    align-items: end;
    gap: 8px;
    transition: 0.3s;
}

.message-box.sender-message {
    flex-direction: row;
}

.message-box.receiver-message {
    flex-direction: row-reverse;
}

.message-body {
    position: relative;
    padding: 2px 8px 8px 8px;
    width: 70%;
    color: #212427;
    word-break: break-word;

    &.tweet-message {
        width: 268px;
        width: 100%;
    }

    .tweeter-card {
        background-color: #1b1d21;
        border-color: #1b1d21 !important;

        &::before {
            content: "";
            position: absolute;
            width: 5px;
            height: calc(100% - 20px);
            top: 10px;
            left: 5px;
            background-color: #35373b;
            border-radius: 5px;
        }
    }

    .tweeter-header {
        font-weight: 400;
    }

    .tweeter-username {
        color: #1d9bd1;
        font-weight: 400;
    }

    .tweet-link {
        color: #1d9bd1;

        &:hover {
            color: #1e9cd2;
        }
    }
}

.message-body.image {
    width: unset;
    max-width: 70%;
}

.sender-message .message-body {
    border-radius: 0px 10px 10px 10px;
    background-color: #f3f4f8;
}

.receiver-message .message-body {
    border-radius: 10px 0px 10px 10px;
    background-color: #e5f6ff;
}

.SUPER_ADMIN .message-body,
.ADMIN .message-body {
    background: #007affb3;
    color: #FFF;
}

.SUPER_ADMIN .message-body::before,
.ADMIN .message-body::before {
    border-color: transparent transparent transparent #007aff;
}

.message-time {
    min-width: max-content;
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color: #0D082C66;
    display: flex;
    align-items: center;
    gap: 2px;
}

.message-time .reaction-emoji-picker {
    cursor: pointer;
}

.message-time .reaction-emoji-picker svg {
    width: 17px;
    height: 17px;
}

.sender-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #202224;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message-menu .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: 0;
}

.message-menu .dropdown-toggle::before {
    border: 0;
}

.message-menu .dropdown-menu {
    min-width: unset;
    border: 1px solid #F2F4F7 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.message-menu .dropdown-menu.show {
    padding: 4px;
    display: flex;
    justify-content: space-evenly;
}

.message-menu .dropdown-item {
    padding: 4px 8px;
    font-size: 12px;
    color: #5b5b5d;
    border-radius: 50px;
}

.lazy-load-image-background {
    width: 100%;
}

.message-image {
    width: 100%;
    height: 200px;
    min-height: 200px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}

.upload-progress-bar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgb(0 0 0 / 30%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-percent {
    padding: 8px 20px;
    width: 60px;
    color: #fff;
    background: #000;
    border-radius: 50px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-viewer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale(0);
    transition: transform 0.3s ease;
    z-index: 9999;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-viewer-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    color: #fff;
    background: #333;
    border: 0;
    border-radius: 50%;
    box-shadow: 0px 0px 5px #333;
    font-size: 31px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button:hover {
    box-shadow: 0px 0px 10px #333;
}

.reply-message {
    border-radius: 8px;
    overflow: hidden;
    background: #e7e8eb;
    cursor: pointer;
}

.reply-message-container {
    padding: 4px 8px;
    border-left: 5px solid #0a1e40;
}

.message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reply-message-body {
    color: #333;
    font-size: 12px;
}

.reply-message-body img {
    height: 50px;
    border-radius: 4px;
}

.chat-box {
    gap: 5px;
    display: flex;
    align-items: center;
}

.chat-box button {
    outline: 0;
}

.chat-input-form {
    position: relative;
    width: 100%;
    display: flex;
}

.chat-input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 20px;
    outline: none;
    flex: 1;
}

.send-button {
    border: none;
    border-radius: 25px !important;
    transition: 0.3s;
    width: 40px;
    height: 40px;
    background: #26E098 !important;
    align-items: center;
    justify-content: center;
    display: flex;

}

.chat-input:valid+.send-button,
.chat-input:focus+.send-button {
    opacity: 1;

}

// .send-button:hover {
//     box-shadow: 0px 0px 5px #007aff;
// }

.chat-window .chat-box aside {
    position: absolute;
    left: 0%;
    bottom: 56px;
    max-height: 60%;
    border-radius: 8px 8px 0 0;
    box-shadow: 0rem -8px 15px rgba(0, 0, 0, 0.175);
    // animation: expandHeight 0.3s ease-out forwards;
}

.emoji-opener {
    padding: 0 2px;
    color: #606163;
    font-size: 25px;
    background: transparent;
    border: 0;
    display: flex;

    &.active {
        color: #3576f1;
    }
}

.EmojiPickerReact>div:last-child {
    display: none;
}

@keyframes expandHeight {
    from {
        height: 0;
    }

    to {
        height: 450px;
    }
}

.gif-picker {
    position: absolute;
    width: 100%;
    height: 450px;
    max-height: 60%;
    left: 0;
    bottom: 56px;
    padding: 15px 10px 0px 10px;
    background-color: #fff;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 8px 8px 0 0;
    box-shadow: 0rem -8px 15px rgba(0, 0, 0, 0.175);
    // animation: expandHeight 0.3s ease-out forwards;
    display: flex;
    flex-direction: column;

    .giphy-search-bar {
        position: relative;
        color: #858585;

        input {
            width: 100%;
            height: 40px;
            padding: 0 30px;
            font-family: sans-serif;
            color: inherit;
            background: rgb(246, 246, 246);
            border-radius: 8px;
            border: 1px solid rgb(246, 246, 246);
            transition: 0.3s;

            &::placeholder {
                color: inherit;
            }

            &:focus {
                outline: none;
                border: 1px solid #007aeb;
            }
        }

        .giphy-search-bar-icon {
            color: inherit;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        .giphy-search-bar-cancel {
            color: inherit;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    .giphy-grid {
        overflow: auto;

        .giphy-gif {
            cursor: pointer;
        }
    }
}

// Chat CSS END

.speech-to-text {
    padding: 0 2px;
    font-size: 25px;
    color: #606163;
    background: transparent;
    border: 0;
    display: flex;
}

table .form-select {
    min-width: 80px;
    font-size: 13px;
    padding: 2px 20px 2px 4px !important;
    background-position: right 2px center !important;
}

table .form-label {
    min-width: 30px;
    margin: 0;
}

table .form-control {
    min-width: 40px;
    font-size: 13px;
    padding: 2px 4px !important;
}

a {
    cursor: pointer;
}

.faq {
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

/* CustomModalStyles.css */

.modal-custom-logout .modal-dialog {
    max-width: 400px;
    /* Set your desired width */
    margin: 1.75rem auto;
    /* Adjusts the modal's position */
}


/* Customizing the modal content */
.modal-custom .modal-content {
    background-color: #FFF !important;
    /* Custom background color for the content */
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
    /* Custom shadow for the content */
}

/* Customizing the modal header */
.modal-custom .modal-header {
    border-bottom: 1px solid #E6E6E6 !important;
    /* Custom border color for the footer */

    background-color: #FFF !important;
    /* Custom background color for the header */
    color: #3F3F41 !important;
    /* Custom text color for the header */
}

.modal-custom .modal-content .modal-body .form-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #5B5B5D;

}

.modal-custom .modal-content .modal-body .form-control {
    height: 50px;
    border-radius: 8px;
    border: 1px;
    background: #F7FBFF;
    border: 1px solid #D4D7E3;
    color: #212427;
}

.modal-custom .modal-content .modal-body .form-select {
    height: 50px;
    // padding: 17px 12px 17px 16px;
    border-radius: 8px;
    border: 1px;
    background: #F7FBFF;
    border: 1px solid #D4D7E3;
    color: #212427;
    background-position: right 0.75rem center !important;
    background-size: 16px 12px !important;
    background-repeat: no-repeat !important;
    background-image: var(--cui-form-select-bg-img), var(--cui-form-select-bg-icon, none) !important;
}




.modal-custom .modal-header .modal-title {
    color: #3F3F41 !important;
    /* Custom text color for the header */
}

/* Customizing the modal footer */
.modal-custom .modal-footer {
    background-color: #FFF !important;
    /* Custom background color for the footer */
    border-top: 1px solid #E6E6E6 !important;
    /* Custom border color for the footer */
}


.modal-custom .modal-footer button:first-child {
    height: 50px;
    padding: 12px 20px 12px 20px;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid #CCCCCC;
    position: absolute;
    left: 25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #212427;

}

.modal-custom .modal-footer button:last-child {
    height: 50px;
    background: #0072C3;
    padding: 12px 36px;
    border-radius: 8px;
    gap: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFF;
}

.align-left {
    text-align: left;
}

.sidebar-nav .nav-title {
    margin: 20px 20px 0px 20px;
    padding: 0;
    border-top: 1px solid rgba(232, 232, 232, 1);
}

.invalid-feedback {
    text-align: left;
}

.subscription-status {
    margin-top: 20px;
    padding: 16px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 8px;
    font-size: 14px;
    text-transform: capitalize;
    color: #000;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.s-box {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    margin-bottom: 8px;
    font-weight: 500;
}

.s-box p {
    text-align: left !important;
}


.s-box p span {
    color: #ddd !important;
    color: #686666 !important;
    max-width: inherit !important;
}

.s-box:last-child {
    margin-bottom: 0;
}


.swal2-confirm:focus,
.swal2-cancel:focus {
    box-shadow: none !important;
}

//custom download dropdown css
.custom-dropdown {
    position: relative;
    user-select: none;
    cursor: pointer;
}

.dropdown-selected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px 10px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #3477F1;
    background: #fff;
    cursor: pointer;
}

.dropdown-options {
    display: grid;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #5B5B5D;
}

.dropdown-option {
    border-bottom: 1px solid #CCC;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-option svg {
    padding-right: 5px;
}

.dropdown-option:last-child {
    border-bottom: 0;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}

.input-svg {
    margin-right: 10px;
}

.left-section-tab {
    gap: 15px;
    width: fit-content !important;
}

.left-section-tab .btn {
    font-size: 14px;
    color: #3576f1;
    background: transparent !important;
    border-color: transparent !important;
    color: #202224 !important;
    font-size: 20px;
    font-weight: 500;
    padding: 0 !important;
    border-radius: 0 !important;
    // background: #fff;
    // border-color: #c1d5fb;
}

.left-section-tab .btn.active {
    color: #3477F1 !important;
    border-bottom: 2px solid #3477F1 !important;
    // background: #3477F1;
    // border-color: #3477F1;
}

.miro-board-container {
    position: relative;
    // height: calc(100vh - 160px);
    height: calc(100vh - 95px);
    background-color: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
}

.live-symbol {
    position: absolute;
    top: -7px;
    right: -2px;
    color: #fff;
    background: #43b85c;
    font-size: 10px;
    padding: 0 8px;
    border-radius: 50px;
    z-index: 9;
}

.live-p-button {
    padding: 0px 12px;
    height: 35px !important;
    color: #fff;
    background-color: #0A1E40;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.live-p-button:hover,
.live-p-button:active,
.live-p-button:disabled {
    color: #fff !important;
    background-color: #0A1E40 !important;
}

.live-s-button {
    padding: 0 12px;
    min-width: 40px;
    height: 35px !important;
    color: #0A1E40;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.live-s-button:hover,
.live-s-button:active,
.live-s-button:disabled {
    color: #0A1E40 !important;
    border: 1px solid #BDBDBD !important;
}

.live-d-button {
    padding: 0 12px;
    min-width: max-content;
    height: 40px !important;
    color: #DE3333;
    background: #FFEBEB;
    border: 1px solid #FF6666;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.live-d-button:hover,
.live-d-button:active,
.live-d-button:disabled {
    color: #DE3333 !important;
    background: #FFEBEB;
    border: 1px solid #FF6666 !important;
}

.add-alert-button {
    background: #26E098 !important;
    color: #fff !important;
    border-color: #26E098 !important;
    display: flex;
    align-items: center;
}

.add-alert-button svg path {
    fill: #fff;
}

.buy-button {
    color: #fff !important;
    background: #26E098 !important;
    border-color: #26E098;
    padding: 12px 20px;
}

.buy-button:hover {
    border-color: #26E098;
    box-shadow: 0px 0px 4px #26E098;
}

.sell-button {
    color: #fff !important;
    background: #E6192E !important;
    border-color: #E6192E;
    padding: 12px 20px;
}

.sell-button:hover {
    border-color: #E6192E;
    box-shadow: 0px 0px 4px #E6192E;
}

.exit-button {
    color: #fff !important;
    background: #3477F1 !important;
    border-color: #3477F1;
    padding: 12px 20px;
}

.exit-button:hover {
    border-color: #3477F1;
    box-shadow: 0px 0px 3px #3477F1;
}

.buy-button.btn-sm,
.sell-button.btn-sm,
.exit-button.btn-sm {
    height: 35px;
    padding: 0 8px;
}

.offer-notice {
    margin: 25px auto;
    padding: 15px 25px;
    width: max-content;
    color: #293040;
    text-align: center;
    border: 1px solid;
    border-radius: 8px;
}

.dropdown-menu {
    border: 1px solid #F2F4F7 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    transform: translate3d(0px, 37px, 0px) !important;

    .dropdown-item {
        font-size: 14px;
        line-height: 16px;
        padding: 8px 10px;
        color: #5b5b5d;
        border-top: 1px solid #F2F4F7;
        display: flex;
        align-items: center;
        gap: 12px;

        &.text-danger:hover {
            background: #FFEBEB;
        }
    }

    li:first-child .dropdown-item {
        border-top: 0;
    }
}

.user-count {
    .dropdown-toggle {
        position: relative;
        padding: 0;
        width: 35px;
        height: 35px;
        border: 1px solid #c1d5fb;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            margin: 0 !important;
            border: 0;
        }
    }

    .live-user-count {
        color: #4a9c52;
        background: #f0f8f0;
        border-color: #c0e2c0;
    }

    .dropdown-menu {
        .dropdown-item {
            background: #0000;
            cursor: default;
        }
    }

    .user-icon {
        width: 33px;
        height: 33px;
        background: #e8e8e8;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .user-info {
        display: block !important;

        .user-role {
            margin-left: 6px;
            padding: 0px 4px;
            color: #fff;
            background-color: #949494;
            font-size: 10px;
            border-radius: 4px;
            text-transform: capitalize;
        }

        .user-name {
            color: #5b5b5d;
            font-size: 15px;
            line-height: 15px;
            display: flex;
            align-items: center;
        }

        .user-email {
            color: #949494;
            font-size: 12px;
        }
    }
}

.modal-custom-90 {
    max-width: 90%;
}


.trade_table_container {
    width: 100%;
    // overflow-x: auto;
}

.modal-body .pagination {
    margin: 0px;
}

.modal-body .trade_table_container {
    height: calc(100vh - 210px);
}

.trade_table {
    width: 100%;
}

.modal-custom-90 .modal-dialog {
    max-width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    min-height: calc(100vh - 3.5rem);
}

.modal-custom-90 .modal-content {
    // display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.live-user-list-container {
    display: flex;
    width: 100%;
    max-height: calc(100vh - 255px);
    align-items: center;
    justify-content: center;
    z-index: 1;

    .live-user-list {
        padding: 50px 0;
        width: 100%;
        height: 100%;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow: auto;
        gap: 10px;

        .live-user {
            position: relative;
            margin: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .user-badge {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background: #ebebeb;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;

            &.active-audio {
                border: 1px solid;
            }
        }

        .user-name {
            margin-top: 4px;
            color: #0A1E40;
            font-size: 15px;
            text-align: center;
        }
    }

    &.fixed {
        width: 100%;
        max-width: 100%;
        padding-top: 15px;

        .live-user-list {
            padding: 0;
            flex-wrap: unset;
            justify-content: start;

            .live-user {
                margin: 12px;

                &:first-child {
                    margin-left: 24px;
                }

                &:last-child {
                    margin-right: 24px;
                }
            }

            .user-badge {
                width: 50px;
                height: 50px;
                font-size: 15px;
            }

            .user-name {
                position: absolute;
                bottom: 0px;
                right: 5px;
                // background: #ebebeb;
                // border-radius: 50%;
                width: 25px;
                height: 25px;
                // border: 1px solid #cccccc;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    display: none;
                }
            }
        }
    }
}

.live-user-text {
    padding: 6px 12px;
    color: #42b85c;
    background: #edfff0;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.or-seprator {
    position: relative;

    div {
        position: relative;
        margin: auto;
        padding: 0 4px;
        width: max-content;
        color: #949494;
        background: #fff;
        font-size: 13px;
        z-index: 1;
    }

    &::before {
        content: "";
        position: absolute;
        top: 52%;
        left: 0;
        right: 0;
        margin: auto;
        width: 50%;
        height: 1px;
        background-color: #949494;
    }
}

.dropdown-action-button {
    width: 34px;
    height: 34px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.screen-share-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;

    .screen-share-pin-container {
        width: 100%;

        .screen-share-list {
            width: 100%;
            height: 100%;

            .screen-share-box {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                video {
                    width: 100%;
                    height: auto;
                    max-height: 100%;
                    border-radius: 8px;
                    opacity: 0;
                    animation: fadeInUp 0.5s ease-in-out forwards;
                    box-shadow: 0 0 0 0.3px #0D498E4D;
                }

                .label {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding: 4px 8px;
                    color: #5b5b5d;
                    background: #e8e8e8;
                    font-weight: 600;
                    font-size: 13px;
                    text-align: center;
                    border-top-left-radius: 8px;
                }

                .img-label {
                    position: absolute;
                    bottom: 35px;
                    right: 8px;
                    padding: 4px 8px;
                    color: #5b5b5d;
                    background: #e8e8e8;
                    font-weight: 600;
                    font-size: 13px;
                    text-align: center;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .fullscreen-label {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .screen-share-unpin-container {
        max-height: 100%;
        overflow-x: auto;
        display: flex;
        gap: 15px;
        width: 100%;

        .screen-share-list {
            cursor: pointer;

            .screen-share-box {
                position: relative;
                // height: 80px;
                // height: 500px;

                video {
                    width: 100%;
                    max-width: 135px;
                    min-width: 135px;
                    height: 90px;
                    object-fit: cover;
                    border-radius: 8px;
                    filter: brightness(0.8);
                    opacity: 0;
                    animation: fadeInUp 0.5s ease-in-out forwards;
                    border: 1px solid #000000;
                }

                .label {
                    // position: absolute;
                    // top: 0;
                    // bottom: 0;
                    // left: 0;
                    // right: 0;
                    padding: 4px;
                    color: #202224;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    text-align: left;
                    display: flex;
                }
            }
        }
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
    }
}

#tnc {
    --cui-form-check-bg: #fff;

    &:valid {
        border-color: var(--cui-border-color);
    }

    &:checked {
        border-color: var(--cui-form-valid-color);
        background-color: var(--cui-form-valid-color);
    }
}

.trade-heading {
    font-size: 24px;
    color: #202224;
    font-weight: 500 !important;
}

.search-items input {
    outline: none;
    border: none;
    width: 100%;

}

.search-items {
    border: 1px solid #20222411;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px;
    background-color: #fff !important;
    border-radius: 4px;
    margin: 10px 0px;
}

.sender-message.ADMIN .message-body,
.receiver-message.ADMIN .message-body,
.sender-message.SUPER_ADMIN .message-body,
.receiver-message.SUPER_ADMIN .message-body {
    background-color: #26E0981A !important;
    // border: 1px solid #2eb85c !important;
    box-shadow: 0 0 0 0.3px #26E098;
}

.sender-message.USER .message-body {
    background-color: #F3F4F8 !important;
}

.receiver-message.USER .message-body {
    background-color: #F3F4F8 !important;
    border: 1px solid #F3F4F8 !important;

}

.message-body,
.receiver-message.ADMIN .message-body,
.sender-message.ADMIN .message-body {
    text-align: right;
    color: #696969 !important;
}

.message-body span {
    text-align: left;
}

.receiver-message.USER .reaction-emoji-picker-button {
    margin-left: -18px;
}

.bot-page {
    margin: 30px 0;
    padding: 26px 30px 30px 30px;
    color: #0D498E;
    background: #FFF;
    border-radius: 10px;

    .bot-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bot-title {
            color: #0D498E;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
        }

        .bot-subtitle {
            color: #0D498E;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }

        .btn-upload {
            color: #fff;
            background: #0d498e;
            border-color: #0d498e;
        }
    }

    .bot-table {
        margin-top: 20px;
        border-radius: 10px;
        background: #F2F2F2;
        overflow: auto;

        .table {
            thead {
                tr {
                    background: #0D498E;

                    th {
                        padding: 15px 30px;
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-align: center;

                        &:first-child {
                            text-align: left;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 10px 30px;
                        color: #0D498E;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-align: center;
                        border-color: transparent;

                        &:first-child {
                            text-align: left;
                        }
                    }
                }
            }
        }


        .bot-info {
            margin: 0 30px;
            padding: 25px 0;
            color: #0D498E;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-top: 1px solid #0D498E;

            li {
                margin: 15px 0 4px 0;

                &:first-child {
                    margin: 0;
                }
            }
        }
    }
}

.pnl_green {
    color: #fff;
    background: #4A9C52;
    border-radius: 6px;
    width: 48px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #F0F8F0;
    margin: 0 auto;
}

.pnl_red {
    color: #fff;
    background-color: #DE3333;
    border-radius: 6px;
    width: 48px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #F0F8F0;
    margin: 0 auto;
}

.login-form {
    &.profile {
        max-width: 50rem;
    }
}

.user-profile-name {
    color: #031F42;
    font-size: 24px;
    font-weight: 300;
    line-height: 30.24px;
    text-align: center;
}

.blue-button {
    padding: 12px 0;
    color: #fff;
    background: #0D498E;
    border-color: #0D498E;
    text-transform: uppercase;

    &:hover {
        color: #fff !important;
        background: #0D498E !important;
        border-color: #0D498E !important;
        opacity: 0.9;
    }
}



.search-input-box {
    position: relative;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.search-input-box button {
    color: grey;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-input-box button:hover {
    color: #333;
}

.search-input-box .search-input {
    padding: 8px 0;
    width: 100%;
    border: 0;
}

.search-input-box .search-input:focus-visible {
    outline: 0;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}